import CampGadgets from '../app/assets/img/camp/campGadgets.jpg';
import { Container, Row, Col } from 'reactstrap';
import { Parallax } from "react-parallax";
import '../App.css';

const CampTwo = () => (   
    <Parallax 
        bgImageStyle={{ opacity: '0.5' }}
        bgImageAlt='Camp Gadgets Background' 
        bgImage={CampGadgets}
        strength={300}
        blur={0}>
        <Container>
            <Row>
                <Col >
                   <p></p>
                   <p></p>
                   <p></p>
                   <p></p>
                   <p></p>
                </Col>
            </Row>
        </Container>
    </Parallax>
);

export default CampTwo;