import WalkingDama from '../app/assets/img/camp/walkingDamaWide.jpg';
import { Container, Row, Col } from 'reactstrap';
import { Parallax } from "react-parallax";
import '../App.css';

const CampThree = () => (   
    <Parallax 
        bgImageStyle={{ opacity: '0.5' }}
        bgImageAlt='Camp Screen Background' 
        bgImage={WalkingDama}
        strength={300}
        blur={0}>
        <Container>
            <Row>
                <Col>
                   <p></p>
                   <p></p>
                   <p></p>
                   <p></p>
                   <p></p>
                   <p></p>                  
                </Col>
            </Row>
        </Container>
    </Parallax>
);

export default CampThree;