import { Row, Col } from 'reactstrap';
import { motion } from 'framer-motion'

const Convention = () => {
    return(
        <>
            <Row id='conventionBG_S1'>
                <Col>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate= {{ opacity: 1 }}
                        transition={{ duration: 2 }}
                    >
                        <h1 id='conventionTitle'>Convencion</h1>
                    </motion.div>
                    <div>
                        <p className='conventionParas'>
                            Irure cillum dolor irure minim. Do aliqua 
                            consectetur laboris est et ex nisi quis sunt 
                            commodo cillum. Occaecat esse esse ut occaecat 
                            aute ullamco nulla ea non laboris cillum ea. Eu 
                            mollit ullamco adipisicing commodo quis pariatur 
                            laborum eu laborum. Eiusmod aliquip dolore nisi 
                            elit nisi aliquip amet aliqua esse adipisicing. 
                            Duis id nulla reprehenderit ullamco amet eu veniam 
                            ex voluptate quis ad duis elit enim.Irure cillum dolor 
                            irure minim. Do aliqua et ex nisi quis sunt
                            consectetur laboris est et ex nisi quis sunt 
                            commodo cillum. Occaecat esse esse ut occaecat 
                            aute ullamco nulla ea non laboris cillum ea. Eu 
                            mollit ullamco adipisicing commodo quis pariatur 
                            laborum eu laborum. Eiusmod aliquip dolore nisi 
                            elit nisi aliquip amet aliqua esse adipisicing. 
                            Duis id nulla reprehenderit ullamco amet eu veniam 
                            ex voluptate quis ad duis elit enim.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row id="conventionBG_S2">
                <Col>
                    <div>
                        <p style={{margin: '15rem 15rem'}}>
                            
                            
                        </p>      
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Convention;