import { SENIORLEADERSHIP, VPLEADERSHIP } from '../app/shared/LEADERSHIP';
import AnimatedLeaderCard from '../components/AnimatedLeaderCard';
import { Container, Row, Col } from 'reactstrap';

const AboutUs = () => {   
    return(
        <Container>
            <Row>
                <Col>
                    <div style={{marginTop: '2rem', textAlign: 'center'}}>
                        <h2><strong>HISTORIAL DE LA ASOCIACION</strong></h2>
                        <p>
                            Aliqua dolore deserunt ut esse aliqua ipsum incididunt aliquip Lorem. Pariatur commodo dolore consequat anim Lorem ullamco qui voluptate aliqua nisi mollit qui ad sit. Cupidatat culpa non occaecat non. Ipsum veniam adipisicing consequat occaecat consequat incididunt minim enim. Velit et nostrud laborum cillum aute esse voluptate officia nulla nulla ipsum esse consequat nulla. Est dolore ea cupidatat ex duis ipsum nulla esse magna esse exercitation.
                            Reprehenderit et deserunt dolor quis Lorem quis enim ad elit culpa. Duis voluptate consequat do velit id exercitation eu anim duis qui minim enim id excepteur. Velit anim eu quis incididunt. Proident nisi commodo commodo nulla duis id reprehenderit culpa ad cupidatat non. Anim excepteur irure aute voluptate dolore. Commodo ullamco qui labore do ipsum exercitation minim occaecat enim amet nostrud consequat ad. Consectetur enim enim nostrud anim do pariatur ipsum.
                            Id anim laboris tempor laboris veniam cillum fugiat quis enim cillum Lorem reprehenderit. Id voluptate ex reprehenderit aliquip aliquip adipisicing fugiat elit aute ut id voluptate qui voluptate. Magna duis sit anim sint.
                        </p>
                        <h2><strong>NUESTRA MISIÓN</strong></h2>
                        <p>
                            El Departamento de Damas “Mensajeras del Señor” del Concilio Latinoamericano 
                            de la Iglesia de Dios Pentecostal, Inc., organizado 17 de septiembre de 1959, 
                            por la gracia del todopoderoso Dios, ha crecido vertiginosamente y se ha extendido 
                            hasta los países del exterior.
                        </p>
                        <h3><strong>PROPÓSITOS</strong></h3>
                        <p>
                            Los propósitos de este departamento son:
                        </p>
                        <ol style={{ textAlign:'left', marginTop: '-2rem' }}>
                            <li>  
                                Promover el compañerismo y fortalecer los lazos de amistad entre las 
                                sociedades de damas de las iglesias y presentar actividades y serie de 
                                programas para mejorar su condición espiritual, moral, material e 
                                intelectual que ayuden a la familia y por consiguiente la Iglesia y la 
                                comunidad.
                            </li>
                            <li>
                                Establecer servicios en los hogares, hospitales, retiros, jubileos, 
                                confraternidades, cultos trimestrales y otras actividades, que promuevan 
                                el avivamiento y el incentivo a trabajar entre las Iglesias del Concilio.
                            </li>
                            <li>
                                Promover un proyecto misionera de unidad en los países extranjeros 
                                estableciendo en ellos nuestra Asociacion.
                            </li>
                        </ol>
                        <br />
                        <hr />
                        <br />
                        <h3><strong>DIRECTIVA INTERNACIONAL</strong></h3>
                        <br />
                        <hr />
                        <Row>
                            { SENIORLEADERSHIP.map((item, idx) => {
                                return(
                                    <Col sm='12' md='6' lg='4' xl='3' className='my-4' key={item.id}>
                                       <AnimatedLeaderCard item={item} idx={idx} />
                                    </Col>  
                                );
                            })}
                        </Row> 
                        <br />
                        <hr /> 
                        <br />
                        <h3><strong>Vice Presidentas Regionales</strong></h3>
                        <br />
                        <hr />
                        <Row>
                            { VPLEADERSHIP.map((item, idx) => {
                                return(
                                    <Col sm='12' md='6' lg='4' xl='3' className='my-4' key={item.id}>
                                        <AnimatedLeaderCard item={item} idx={idx} />
                                    </Col>  
                                );
                            })}
                        </Row>                               
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutUs;