import { Row, Col } from 'reactstrap';
import { motion } from 'framer-motion';


const CampTextBoxTwo = () => {
    return(
        <Row id='events' className='campCSS'>
            <Col>
                <motion.div
                    className="campTextBox" 
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.5, duration: 2, type: 'tween' }}
                    viewport={{ once: true }}>
                    <h1 style={{ color: '#FFFFFF', marginRight:'1rem' }}>Eventos</h1>
                    <a href="#welcome" style={{ alignContent:'center'}}>
                        <i className="fa fa-arrow-circle-up fa-2x" style={{color: '#FFFFFF'}} />
                    </a>
                </motion.div>
                <motion.div 
                    initial={{ opacity: 0, x: 300 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 1, duration: 3 }}
                    viewport={{ once: true }}>
                        <p>
                            Nostrud tempor ex in do quis. Sint mollit esse culpa Lorem deserunt sint 
                            pariatur sint velit enim qui ea. Consectetur mollit pariatur qui dolor voluptate 
                            labore est aliquip ut reprehenderit magna ex. Quis ea cillum proident nostrud 
                            voluptate occaecat consectetur dolore. Adipisicing commodo aliqua ex anim et sint 
                            nisi esse in culpa consectetur commodo duis. Officia do velit in dolore mollit duis. 
                            Consequat tempor id enim dolor id voluptate enim fugiat deserunt id mollit.
                            Nostrud tempor ex in do quis. Sint mollit esse culpa Lorem deserunt sint 
                            pariatur sint velit enim qui ea. Consectetur mollit pariatur qui dolor voluptate 
                            labore est aliquip ut reprehenderit magna ex. Quis ea cillum proident nostrud 
                            voluptate occaecat consectetur dolore. Adipisicing commodo aliqua ex anim et sint 
                            nisi esse in culpa consectetur commodo duis. Officia do velit in dolore mollit duis. 
                            Consequat tempor id enim dolor id voluptate enim fugiat deserunt id mollit.
                            Nostrud tempor ex in do quis. Sint mollit esse culpa Lorem deserunt sint 
                            pariatur sint velit enim qui ea. Consectetur mollit pariatur qui dolor voluptate 
                            labore est aliquip ut reprehenderit magna ex. Quis ea cillum proident nostrud 
                            voluptate occaecat consectetur dolore. Adipisicing commodo aliqua ex anim et sint 
                            nisi esse in culpa consectetur commodo duis. Officia do velit in dolore mollit duis. 
                            Consequat tempor id enim dolor id voluptate enim fugiat deserunt id mollit.
                        </p>
                </motion.div>
            </Col>
        </Row>
    );
};

export default CampTextBoxTwo;